import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable()
export class SidebarService {
  private _isSidebarOpenSubject = new BehaviorSubject<boolean>(true);

  public get isSidebarOpen(): boolean {
    return this._isSidebarOpenSubject.value;
  }

  public get onSidebarToggle(): Observable<boolean> {
    return this._isSidebarOpenSubject.asObservable();
  }

  public toggleSidebar(): void {
    this._isSidebarOpenSubject.next(!this.isSidebarOpen);
  }

  public openSidebar(): void {
    if (!this.isSidebarOpen)
      this._isSidebarOpenSubject.next(true);
  }

  public closeSidebar(): void {
    if (this.isSidebarOpen)
      this._isSidebarOpenSubject.next(false);
  }
}
