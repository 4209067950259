import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConstants } from 'app/main/shared/constants/api.constants';
import { DistributionItem } from 'app/main/shared/models/distribution-item';
import { DistributionQuery } from 'app/main/shared/models/distribution-query';
import { DistributionSend } from 'app/main/shared/models/distribution-send';
import { DistributionStatus } from 'app/main/shared/models/distribution-status';
import { ReportSource } from 'app/main/shared/models/report-source';
import { PaginatedResult } from 'app/main/shared/paging/paginated.result.model';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class DistributionService {
  constructor(private http: HttpClient, private api: ApiConstants) { }

  item(id: number): Observable<DistributionItem> {
    return this.http.get<DistributionItem>(this.api.Distribution.item, { params: { id: id.toString() } });
  }

  query(filter: DistributionQuery): Observable<PaginatedResult<DistributionItem>> {
    return this.http.post<PaginatedResult<DistributionItem>>(this.api.Distribution.query, filter, {
      params: {
        page: filter.pageNum.toString(),
        pageSize: filter.pageSize.toString(),
      }
    });
  }

  // approve(savedReportIds: number[]): Observable<null> {
  //   return this.http.post<null>(this.api.Distribution.approve, savedReportIds);
  // }

  send(parms: DistributionSend): Observable<DistributionStatus[]> {
    return this.http.post<DistributionStatus[]>(this.api.Distribution.send, parms);
  }

  delete(savedReportIds: number[]): Observable<null> {
    return this.http.post<null>(this.api.Distribution.delete, savedReportIds);
  }

  createReportItem(reportSource: ReportSource): Observable<number> {
    return this.http.post<number>(this.api.Distribution.createReportItem, reportSource);
  }

  uploadAttachment(files: File[]): Observable<any> {
    const formData = new FormData();
    files.forEach(file => {
      formData.append('file', file, file.name);
    });
    return this.http.post(this.api.Distribution.uploadAttachment, formData, { reportProgress: true, observe: 'events' });
  }

  updateAttachmentItems(attachmentEntities: DistributionItem[]): Observable<any> {
    return this.http.post<any>(this.api.Distribution.updateAttachmentItems, attachmentEntities);
  }

  openAttachment(attachmentEntity: DistributionItem): string {
    return this.api.Distribution.openAttachment + "/" + attachmentEntity.attachedFile;
  }

  downloadAttachmentURL(attachmentEntity: DistributionItem): string {
    return this.api.Distribution.downloadoAttachment + "/" + attachmentEntity.attachedFile;
  }
}
