import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiConstants } from "app/main/shared/constants/api.constants";
import {
  Billing,
  BillingReportType,
  IntAcctStatus,
  AdminUpdate,
  TimesheetDesc,
  NewRow,
  RowChanges,
} from "app/main/shared/models/billing.model";
import { PaginatedResult } from "app/main/shared/paging/paginated.result.model";
import { subDays, endOfWeek, format, parseISO, startOfWeek } from "date-fns";
import { isMoment } from "moment";
import { BehaviorSubject, Observable } from "rxjs";

interface IntAcctStatusResponse {
  contentType: string | null;
  jsonSerializerOptions: string | null;
  statusCode: string | null;
  value: string;
}

export interface QueryParams {
  type: BillingReportType;
  start: string;
  end?: string;
  projectNumber: string | null;
  employee: string | null;
  pageNum: number;
  pageSize: number;
  orderByField: string;
  orderByDescending: boolean;
  isExpense: boolean;  
}

interface RawWeek {
  start: Date;
  end: Date;
}

interface SortParams {
  sortColumn: string;
  sortDescending: boolean;
}

export interface TableTotals {
  overtimeHours: number;
  regularHours: number;
  totalMiles: number;
  adminHours: number;
  supportHours: number;
  [key: string]: number;
}

export interface Week {
  start: string;
  end: string;
}

@Injectable()
export class BillingService {
  private date = new Date();
  private dateFormat = "MM/dd/yyyy";
  public defaultStart = startOfWeek(this.date).toISOString();
  public defaultEnd = endOfWeek(this.date).toISOString();
  public initialTotals: Billing = {
    fieldReportID: 0,
    projectNumber: "",
    isBillable: false,
    regularHours: 0.0,
    overtimeHours: 0.0,
    adminHours: null,
    supportHours: null,
    regularBillingCode: 0,
    overtimeBillingCode: 0,
    intacctNeedsToBeUpdated: false,
    totalMiles: 0.0,
    rawTime: 0.0,
    adminDescription: '',
    timesheetDescription: "",
    intacctTimesheetID: "",
    createdDate: this.date,
    updatedDate: this.date,
    employeeNumber: "",
    date: this.date,
    subject: "",
    projectName: "",
    employee: "",
    milesPerTrip: null,
    trips: 0,
    fieldReportCount: 0,
    fieldReport: null,
    id: 101,
    isDeleted: false,
    syncToIntacct: false,
  };

  public billableChanges: BehaviorSubject<RowChanges> =
    new BehaviorSubject<RowChanges>({});
  public billingRows: BehaviorSubject<Billing[]> = new BehaviorSubject<
    Billing[]
  >([]);

  private visibleRowsSubject: BehaviorSubject<Billing[]> = new BehaviorSubject<
    Billing[]
  >([]);
  public duplicating: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public paramsPage: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public pendingChanges: BehaviorSubject<number[]> = new BehaviorSubject<
    number[]
  >([]);
  public queryParams: BehaviorSubject<QueryParams> =
    new BehaviorSubject<QueryParams>({
      type: BillingReportType.Hours,
      start: this.defaultStart,
      end: this.defaultEnd,
      projectNumber: null,
      employee: null,
      pageNum: 0,
      pageSize: 1000,
      orderByField: "projectName",
      orderByDescending: false,
      isExpense: false
  });
  public routeChange: BehaviorSubject<string> = new BehaviorSubject<string>("");
  public sortParams: BehaviorSubject<SortParams> =
    new BehaviorSubject<SortParams>({
      sortColumn: "projectName",
      sortDescending: false,
    });
  public stopLeave: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public syncableChanges: BehaviorSubject<RowChanges> =
    new BehaviorSubject<RowChanges>({});
  public syncStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public syncableRows: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public syncValid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public syncWeekValid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true
  );
  public totals: BehaviorSubject<TableTotals> =
    new BehaviorSubject<TableTotals>({
      totalMiles: 0,
      regularHours: 0,
      overtimeHours: 0,
      adminHours: 0,
      supportHours: 0,
    });
  public adminDescription: BehaviorSubject<string> =
    new BehaviorSubject<string>("");
  public timesheetDescription: BehaviorSubject<string> =
    new BehaviorSubject<string>("");
  public timesheetDescriptionDirty: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public view: BehaviorSubject<BillingReportType> =
    new BehaviorSubject<BillingReportType>(BillingReportType.Hours);
  public viewWeek: BehaviorSubject<Week> = new BehaviorSubject<Week>({
    start: format(startOfWeek(parseISO(this.defaultStart)), this.dateFormat),
    end: format(endOfWeek(parseISO(this.defaultStart)), this.dateFormat),
  });

  constructor(private http: HttpClient, private api: ApiConstants) { }

  // GETTERS
  get currentBillableChanges(): Observable<RowChanges> {
    return this.billableChanges.asObservable();
  }

  get currentPendingChanges(): Observable<number[]> {
    return this.pendingChanges.asObservable();
  }

  get currentSyncableChanges(): Observable<RowChanges> {
    return this.syncableChanges.asObservable();
  }

  get currentTotals(): Observable<TableTotals> {
    return this.totals.asObservable();
  }

  get currentView(): Observable<BillingReportType> {
    return this.view.asObservable();
  }

  get hasDuplicates(): Observable<boolean> {
    return this.duplicating.asObservable();
  }

  get isLoading(): Observable<boolean> {
    return this.loading.asObservable();
  }

  get isStopingLeave(): Observable<boolean> {
    return this.stopLeave.asObservable();
  }

  get page(): Observable<number> {
    return this.paramsPage.asObservable();
  }

  get params(): Observable<QueryParams> {
    return this.queryParams.asObservable();
  }

  get pendingRoute(): Observable<string> {
    return this.routeChange.asObservable();
  }

  get sortingParams(): Observable<SortParams> {
    return this.sortParams.asObservable();
  }

  get syncing(): Observable<boolean> {
    return this.syncStatus.asObservable();
  }

  get syncingRows(): Observable<boolean> {
    return this.syncableRows.asObservable();
  }

  get syncingValid(): Observable<boolean> {
    return this.syncValid.asObservable();
  }

  get syncingWeekValid(): Observable<boolean> {
    return this.syncWeekValid.asObservable();
  }

  get adminDesc(): Observable<string> {
    return this.adminDescription.asObservable();
  }

  get tsDescription(): Observable<string> {
    return this.timesheetDescription.asObservable();
  }

  get tsDescriptionDirty(): Observable<boolean> {
    return this.timesheetDescriptionDirty.asObservable();
  }

  get updatedRows(): Observable<Billing[]> {
    return this.billingRows.asObservable();
  }

  get visibleRows(): Observable<Billing[]> {
    return this.visibleRowsSubject.asObservable();
  }

  get week(): Observable<Week> {
    return this.viewWeek.asObservable();
  }

  // SETTERS
  setBillableChanges(value: RowChanges): void {
    this.billableChanges.next(value);
  }

  setDuplicating(value: boolean): void {
    this.duplicating.next(value);
  }

  setLoading(value: boolean): void {
    this.loading.next(value);
  }

  setParams(values: QueryParams): void {
    this.queryParams.next(values);
  }

  setPendingChanges(value: number[]): void {
    this.pendingChanges.next(value);
  }

  setPendingRoute(values: string): void {
    this.routeChange.next(values);
  }

  setPage(values: number): void {
    this.paramsPage.next(values);
  }

  setSortingParams(values: SortParams): void {
    this.sortParams.next(values);
  }

  setStopLeave(value: boolean): void {
    this.stopLeave.next(value);
  }

  setSyncableChanges(value: RowChanges): void {
    this.syncableChanges.next(value);
  }

  setSyncing(value: boolean): void {
    this.syncStatus.next(value);
  }

  setSyncingRows(value: boolean): void {
    this.syncableRows.next(value);
  }

  setSyncingValid(value: boolean): void {
    this.syncValid.next(value);
  }

  setSyncingWeekValid(value: boolean): void {
    this.syncWeekValid.next(value);
  }

  setTotals(value: TableTotals): void {
    this.totals.next(value);
  }

  setAdminDescription(value: string): void {
    this.adminDescription.next(value);
  }

  setTSDescription(value: string): void {
    this.timesheetDescription.next(value);
  }

  setTSDescriptionDirty(value: boolean): void {
    this.timesheetDescriptionDirty.next(value);
  }

  setUpdatedRows(value: Billing[]): void {
    this.billingRows.next(value);
  }

  setVisibleRows(value: Billing[]): void {
    this.visibleRowsSubject.next(value);
  }

  setView(value: BillingReportType): void {
    this.view.next(value);
  }

  setWeek(values: Week): void {
    this.viewWeek.next(values);
  }

  // HELPERS
  cleanDate(date: any): string {
    if (isMoment(date)) {
      return new Date((date as unknown as any)._d).toISOString();
    }

    return date;
  }

  fmtWeek(date: any): RawWeek {
    // TODO: date sometimes returns as Moment object, this is a workaround to extract the ISO string is that is the case
    const selectedDate: string = this.cleanDate(date);
    const start = startOfWeek(parseISO(selectedDate));
    const end = endOfWeek(parseISO(selectedDate));

    return {
      start,
      end,
    };
  }

  // REQUESTS
  admin(queryParams: QueryParams): Observable<PaginatedResult<Billing>> {
    const { start, end } = this.fmtWeek(queryParams.start);

    this.setLoading(true);
    this.setWeek({
      start: format(start, this.dateFormat),
      end: format(end, this.dateFormat),
    });

    return this.http.post<PaginatedResult<Billing>>(this.api.Billing.query, {
      reportType: BillingReportType.Admin,
      userId: null,
      projectNumber: queryParams.projectNumber || null,
      employee: queryParams.employee || null,
      pageNum: queryParams.pageNum,
      pageSize: queryParams.pageSize,
      startDate: queryParams.start,
      endDate: queryParams.end,
      orderByField: queryParams.orderByField,
      orderByDescending: queryParams.orderByDescending,
    });
  }

  reports(queryParams: QueryParams): Observable<PaginatedResult<Billing>> {
        const { start, end } = this.fmtWeek(queryParams.start);

    this.setLoading(true);
    this.setWeek({
      start: format(start, this.dateFormat),
      end: format(end, this.dateFormat),
    });

    return this.http.post<PaginatedResult<Billing>>(this.api.Billing.query, {
      reportType: queryParams.type,
      userId: null,
      projectNumber: queryParams.projectNumber || null,
      employee: queryParams.employee || null,
      pageNum: queryParams.pageNum,
      pageSize: queryParams.pageSize,
      startDate: queryParams.start,
      endDate: queryParams.end,
      orderByField: queryParams.orderByField,
      orderByDescending: queryParams.orderByDescending,
    });
  }

  downloadProjectorReport(queryParams: QueryParams): Observable<Blob> {
    return this.http.post<Blob>(this.api.Billing.downloadprojectorbillingreport, {
      ReportType: queryParams.type,
      ProjectNumber: queryParams.projectNumber || null,
      Employee: queryParams.employee || null,
      StartDate: queryParams.start,
      EndDate: queryParams.end,
      OrderbyField: queryParams.orderByField,
      OrderbyDescending: queryParams.orderByDescending,
      IsExpense: queryParams.isExpense
    }, { responseType: 'blob' as 'json' });
}

  updateReport(payload: Billing[]): Observable<void> {
    this.setLoading(true);

    return this.http.put<void>(this.api.Billing.save, payload);
  }

  saveAdmin(payload: AdminUpdate[]): Observable<void> {
    this.setLoading(true);

    return this.http.put<void>(this.api.Billing.saveAdmin, payload);
  }

  saveTimesheetDesc(payload: TimesheetDesc): Observable<void> {
    this.setLoading(true);

    return this.http.post<void>(
      this.api.Billing.saveTimesheetDescription,
      payload
    );
  }

  sendToIntAcct(payload: IntAcctStatus): Observable<IntAcctStatusResponse> {
    let { start, end } = this.fmtWeek(payload.date);

    if (payload.type === BillingReportType.Admin)
      start = subDays(start, 21);

    this.setLoading(true);
    const idList = this.visibleRowsSubject.getValue().map(row => row.id);

    return this.http.post<IntAcctStatusResponse>(
      this.api.Billing.sendtointacct,
      {
        billingReportType: payload.type,
        start: format(start, this.dateFormat),
        end: format(end, this.dateFormat),
        idList
      }
    );
  }

  checkIntAcct(payload: IntAcctStatus): Observable<boolean> {
    const { start, end } = this.fmtWeek(payload.date);

    this.setLoading(true);

    return this.http.get<boolean>(this.api.Billing.syncprogress, {
      params: {
        start: format(start, this.dateFormat),
        end: format(end, this.dateFormat),
      },
    });
  }
}
