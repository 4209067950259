import { animate, animateChild, group, query, state, style, transition, trigger } from "@angular/animations";

export const SidebarAnimations = [
  trigger('navigationToggle', [
    state('0', style({
      'padding-left': '220px',
      'margin-left': '-220px'
    })),
    state('1', style({
      'padding-left': '0px',
      'margin-left': '0px'
    })),

    transition('0 => 1', group([
      animate('250ms'),
      query('@fadeLogo', [animateChild()]),
      query('@shiftCollapsedItems', [animateChild()]),
    ])),

    transition('1 => 0', group([
      query('@shiftCollapsedItems', [animateChild()]),
      query('@fadeLogo', [animateChild()]),
      animate('250ms'),
    ]))
  ]),

  trigger('transferToggleButton', [
    state('0', style({
      'left': '10px'
    })),
    state('1', style({
      'left': '230px'
    })),
    transition('0 => 1', animate('250ms')),
    transition('1 => 0', animate('250ms'))
  ]),

  trigger('fadeLogo', [
    state('0', style({
      'display': 'flex',
      'opacity': 1
    })),
    state('1', style({
      'display': 'none',
      'opacity': 0
    })),
    transition('0 => 1', animate('250ms ease-in')),
    transition('1 => 0', animate('250ms ease-out'))
  ]),

  trigger('shiftCollapsedItems', [
    state('0', style({
      'padding-left': '56px'
    })),
    state('1', style({
      'padding-left': '24px'
    })),
    transition('* => *', animate('250ms'))
  ])
]
