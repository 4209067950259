import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { ReportAutomationDialogComponent } from './report-automation-dialog.component';
import { DistributionService } from 'app/services/distribution.service';
import { PipesModule } from '../../pipes/pipes.module';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatDialogModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatTableModule,
    PipesModule,
  ],
  declarations: [ReportAutomationDialogComponent],
  entryComponents: [ReportAutomationDialogComponent],
  exports: [ReportAutomationDialogComponent],
  providers: [DistributionService]
})
export class ReportAutomationDialogModule { }
