import {
  ActivatedRouteSnapshot,
  CanDeactivate,
  RouterStateSnapshot,
} from "@angular/router";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { BillingService } from "app/services/billing.service";

export interface IDeactivateComponent {
  canExit: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({ providedIn: "root" })
export class ChangesGuard implements CanDeactivate<IDeactivateComponent> {
  constructor(private billingService: BillingService) {}

  canDeactivate(
    component: IDeactivateComponent,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const cleanRoute = nextState.url?.replace(/^\//g, '');

    this.billingService.setPendingRoute(cleanRoute ?? "");

    return component?.canExit ? component?.canExit() : true;
  }
}
