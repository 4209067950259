import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from 'app/services/authentication.service';
import { Authentication } from '../shared/models/authentication.model';

@Injectable({ providedIn: 'root' })
export class RoleGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const expectedRole: string[] = route.data.expectedRole;
    let authentication: Authentication = JSON.parse(localStorage.getItem('authentication'))

    if (this.authenticationService.isAuthenticated() && expectedRole.some(role => role === authentication.role)) {
      return true;
    }

    this.router.navigate(['']);
    return false;
  }
}