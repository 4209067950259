import { Injectable, OnDestroy, PipeTransform } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { TextMaskConfig } from 'angular2-text-mask';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FormHelper implements OnDestroy {
  private subscriptions: Subscription[] = [];

  constructor() {

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public markControlsAsTouched(form: FormGroup): void {
    for (let controlName in form.controls) {
      form.get(controlName).markAsTouched();
    }
  }

  public markControlsAsDisabled(form: FormGroup, exceptControlNames: string[] = []): void {
    for (let controlName in form.controls) {
      if (exceptControlNames.some(x => x == controlName)) continue;
      form.get(controlName).disable();
    }
  }

  public markControlsAsEnabled(form: FormGroup, exceptControlNames: string[] = []): void {
    for (let controlName in form.controls) {
      if (exceptControlNames.some(x => x == controlName)) continue;
      form.get(controlName).enable();
    }
  }

  public formatDecimalValue(originalValue: string, decimalCount: number = 1): string {
    let value: number = parseFloat(originalValue);
    if (!isNaN(value)) {
      let formattedValue = Math.round(value * Math.pow(10, decimalCount)) / Math.pow(10, decimalCount);
      let formattedValueString = String(formattedValue);
      if (formattedValue % 1 === 0) {
        formattedValueString += '.' + '0'.repeat(decimalCount);
      } else {
        let missingZeros = decimalCount - formattedValueString.split('.')[1].length;
        formattedValueString += '0'.repeat(missingZeros);
      }
      return formattedValueString;
    }
    return originalValue;
  }

  public updateFormValues(form: FormGroup, object: any) {
    for (let controlName of Object.keys(form.controls)) {
      form.controls[controlName].setValue(object[controlName]);
    }
  }

  public get timeMask(): TextMaskConfig {
    return {
      guide: true,
      keepCharPositions: true,
      showMask: false,
      mask: [/\d/, /\d/, ':', /\d/, /\d/, ' ', /[PApa]/, 'M']
    } as TextMaskConfig;
  }

  public applyTransformPipe(control: AbstractControl, pipe: PipeTransform): void {
    this.subscriptions.push(
      control.valueChanges.subscribe(value => {
        control.setValue(pipe.transform(value), {
          emitEvent: false
        });
      })
    );
  }
}