import { Injectable } from "@angular/core";
import { HttpParams } from "@angular/common/http";

@Injectable()
export class HttpHelper {
  public arrayToHttpParams(paramName: string, array: any[], params: HttpParams = null): HttpParams {
    if (!params) {
      params = new HttpParams();
    }

    array.forEach(element => {
      if (element !== null)
        params = params.append(paramName, element.toString());
    });

    return params;
  }
}