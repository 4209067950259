import { AuthGuard } from "app/main/authentification/guard";
import { ChangesGuard } from "app/main/billing/guard";
import { RoleGuard } from "app/main/authentification/role-guard";

export const RouteConstants = [
  {
    path: 'field-reports/soil-test/:id',
    loadChildren: () => import('../../field-reports/detail/soil-test-detail/field-report-soil-test-detail.module').then(m => m.FieldReportSoilTestDetailModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'field-reports/concrete-test/:id',
    loadChildren: () => import('../../field-reports/detail/concrete-test-detail/field-report-concrete-test-detail.module').then(m => m.FieldReportConcreteTestDetailModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'field-reports/:id',
    loadChildren: () => import('../../field-reports/detail/field-report-detail.module').then(m => m.FieldReportDetailModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'field-reports',
    loadChildren: () => import('../../field-reports/field-reports.module').then(m => m.FieldReportsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'soil-tests/new',
    loadChildren: () => import('../../soil-tests/create/soil-test-create.module').then(m => m.SoilTestCreateModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'soil-tests/:id',
    loadChildren: () => import('../../soil-tests/detail/soil-test-detail.module').then(m => m.SoilTestDetailModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'soil-tests',
    loadChildren: () => import('../../soil-tests/soil-tests.module').then(m => m.SoilTestsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'logs',
    loadChildren: () => import('../../logs/logs.module').then(m => m.LogsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'users',
    loadChildren: () => import('../../administration/users/users.module').then(m => m.UsersModule),
    canActivate: [RoleGuard],
    data: {
      expectedRole: ['Admin']
    }
  },
  {
    path: 'lookups',
    loadChildren: () => import('../../administration/lookups/lookups.module').then(m => m.LookupsModule),
    canActivate: [RoleGuard],
    data: {
      expectedRole: ['Admin']
    }
  },
  {
    path: 'settings',
    loadChildren: () => import('../../administration/settings/settings.module').then(m => m.SettingsModule),
    canActivate: [RoleGuard],
    data: {
      expectedRole: ['Admin']
    }
  },
  {
    path: 'reports/builder',
    loadChildren: () => import('../../reports/builder/report-builder.module').then(m => m.ReportBuilderModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'reports/distribution',
    loadChildren: () => import('../../reports/distribution/report-distribution.module').then(m => m.ReportDistributionModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'concrete/tests/:id',
    loadChildren: () => import('../../concrete/detail/concrete-detail.module').then(m => m.ConcreteDetailModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'concrete/tests',
    loadChildren: () => import('../../concrete/detail/concrete-detail.module').then(m => m.ConcreteDetailModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'concrete/search-by-project',
    loadChildren: () => import('../../concrete/search-by-project/search-by-project.module').then(m => m.SearchByProjectModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'concrete/break-list',
    loadChildren: () => import('../../concrete/break-list/break-list.module').then(m => m.BreakListModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'concrete/cylinder-check-in/:id',
    loadChildren: () => import('../../field-reports/detail/concrete-test-detail/field-report-concrete-test-detail.module').then(m => m.FieldReportConcreteTestDetailModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'concrete/cylinder-check-in',
    loadChildren: () => import('../../concrete/cylinder-check-in/cylinder-check-in.module').then(m => m.CylinderCheckInModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'concrete/approve-pours/:id',
    loadChildren: () => import('../../field-reports/detail/concrete-test-detail/field-report-concrete-test-detail.module').then(m => m.FieldReportConcreteTestDetailModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'concrete/approve-pours',
    loadChildren: () => import('../../concrete/approve-concrete-pours/approve-concrete-pours.module').then(m => m.ApproveConcretePoursModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'billing',
    loadChildren: () => import('../../billing/billing.module').then(m => m.BillingModule),
    canActivate: [AuthGuard],
    canDeactivate: [ChangesGuard]
  },
  {
    path: 'contacts',
    loadChildren: () => import('../../contacts/contacts.module').then(m => m.ContactsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'projects/:id',
    loadChildren: () => import('../../projects/detail/project-detail.module').then(m => m.ProjectDetailModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'projects',
    loadChildren: () => import('../../projects/projects.module').then(m => m.ProjectsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'discrepancy',
    loadChildren: () => import('../../logs/discrepancy/discrepancy.module').then(m => m.DiscrepancyModule)
  },
  {
    path: 'rock',
    loadChildren: () => import('../../logs/rock/rock.module').then(m => m.RockModule)
  },
  {
    path: 'undercut',
    loadChildren: () => import('../../logs/undercut/undercut.module').then(m => m.UndercutModule)
  },
  {
    path: 'error',
    loadChildren: () => import('../../shared/components/error/error.module').then(m => m.ErrorModule),
  },
  {
    path: '404',
    loadChildren: () => import('../../shared/components/error-404/error-404.module').then(m => m.Error404Module),
  },
  {
    path: '',
    redirectTo: '',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: '404'
  }
];