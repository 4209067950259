<mat-dialog-content>
  <h2 mat-dialog-title>Confirmation</h2>
  <h4>
    {{ text }}
  </h4>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-raised-button color="warn" (click)="yes()">Yes</button>
  <button mat-raised-button color="primary" (click)="no()">No</button>
</mat-dialog-actions>

<div class="mb-12"></div>