import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/finally';

@Injectable()
export class ProgressBarInterceptor implements HttpInterceptor {
  constructor(
    private progressBarService: FuseProgressBarService,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.progressBarService.show();

    let cancelled = true;
    return next.handle(request).do(
      undefined,
      () => {
        // error
        this.progressBarService.hide();
        cancelled = false;
      },
      () => {
        this.progressBarService.hide();
        // completed
        cancelled = false;
      },
    ).finally(
      () => {
        if (cancelled) {
          this.progressBarService.hide();
        }
      },
    );
  }
}