import { FieldReport } from "./field-report.model";

export enum BillingReportType {
  Hours = 0,
  Admin,
}

export class Billing {
  id: number;
  type?: BillingReportType;

  date: Date;
  employee: string;
  employeeNumber: string;

  fieldReportID: number;
  fieldReport: FieldReport | null;
  fieldReportCount: number;

  milesPerTrip: number | null;
  overtimeBillingCode: number | null;
  overtimeHours: number;
  projectName: string;
  projectNumber: string;
  regularBillingCode: number | null;
  regularHours: number;
  adminHours: number | null;
  supportHours: number | null;
  subject: string;
  trips: number | null;
  totalMiles: number | null;
  rawTime: number | null;
  isBillable: boolean;
  syncToIntacct: boolean;

  intacctTimesheetID: string;
  intacctNeedsToBeUpdated: boolean;
  adminDescription: string | null;
  timesheetDescription: string | null;

  createdDate: Date;
  updatedDate: Date | null;
  isDeleted: boolean;
  toBeUpdated?: boolean;
  duplicate?: boolean;
}

export interface IntAcctStatus {
  type: BillingReportType;
  date: any;
}

export interface AdminUpdate {
  fieldReportID: number;
  adminHours: number;
  supportHours: number;
  regularBillingCode: number;
  timesheetDescription: string;
  isBillable: boolean;
  syncToIntacct: boolean;
}

export interface TimesheetDesc {
  billingreporttype: BillingReportType;
  Start: string;
  End: string;
  Description: string;
}

export interface NewRow {
  id: number;
  fieldReportID: number;
  regularHours: number;
  overtimeHours: number;
  regularBillingCode: number;
  totalMiles: number | null;
  timesheetDescription: string;
}

export interface BillingCSV {
  Date: Date;
  "Project Number": string;
  "Project Name": string;
  "Employee #": string;
  "Employee Name": string;
  Service?: string;
  "Field Reports"?: number;
  "Reg Hours": number;
  "OT Hours"?: number;
  Trips?: number;
  Miles?: number;
  "RT Code": number;
  "OT Code"?: number;
  "Raw Time"?: number;
}

export interface RowChanges {
  [key: string]: boolean;
}
