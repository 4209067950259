import { Injectable } from '@angular/core'
import { DistributionPermissionSet } from 'app/main/shared/models/distribution-permission.set.model'
import { BillingPermissionSet } from 'app/main/shared/models/permissions/billing-permission-set.model'
import { ConcretePermissionSet } from 'app/main/shared/models/permissions/concrete-permission-set.model'
import { ContactPermissionSet } from 'app/main/shared/models/permissions/contact-permission-set.model'
import { FieldPermissionSet } from 'app/main/shared/models/permissions/field-permission-set.mode'
import { ProjectPermissionSet } from 'app/main/shared/models/permissions/project-permission-set.model'
import { SoilsPermissionSet } from 'app/main/shared/models/permissions/soils-persmission-set.model'
import { AuthenticationService } from './authentication.service'

@Injectable()
export class PermissionService {
  public readonly roles = {
    'Power User': 1,
    Admin: 2,
    Manager: 3,
    Approver: 4,
    User: 5,
    Remote: 6,
  }

  constructor(private authenticationService: AuthenticationService) {}

  public get userPermissionLevel(): number {
    return this.roles[
      this.authenticationService.currentAuthentication.loggedUser.userType
    ]
  }

  public getPermissionLevel(userType: string): number {
    return this.roles[userType]
  }

  public get projectPermissionSet(): ProjectPermissionSet {
    const canEdit =
      this.userPermissionLevel === this.getPermissionLevel('Power User') ||
      this.userPermissionLevel === this.getPermissionLevel('Admin')
    let permissionSet = new ProjectPermissionSet()

    if (canEdit) {
      permissionSet.synchronize = true
      permissionSet.editForm = true
      permissionSet.editDistribution = true
      permissionSet.editMixDesigns = true
    }
    return permissionSet
  }

  public get contactPermissionSet(): ContactPermissionSet {
    const canEdit =
      this.userPermissionLevel === this.getPermissionLevel('Power User') ||
      this.userPermissionLevel === this.getPermissionLevel('Admin')
    let permissionSet = new ContactPermissionSet()

    if (canEdit) {
      permissionSet.create = true
      permissionSet.edit = true
    }
    return permissionSet
  }

  public get billingPermissionSet(): BillingPermissionSet {
    const canEdit =
      this.userPermissionLevel === this.getPermissionLevel('Power User') ||
      this.userPermissionLevel === this.getPermissionLevel('Admin')
    let permissionSet = new BillingPermissionSet()

    if (canEdit) {
      permissionSet.create = true
      permissionSet.edit = true
    }
    return permissionSet
  }

  public get fieldPermissionSet(): FieldPermissionSet {
    const canEdit =
      this.userPermissionLevel === this.getPermissionLevel('Power User') ||
      this.userPermissionLevel === this.getPermissionLevel('Admin')
    let permissionSet = new FieldPermissionSet()

    if (canEdit) {
      permissionSet.editForm = true
      permissionSet.approve = true
      permissionSet.delete = true
      permissionSet.removeReportCreated = true
      permissionSet.markReportCreated = true
      permissionSet.sendPrelim = true
      permissionSet.viewReport = true
      permissionSet.createReportForDistribution = true
      permissionSet.sendReport = true
      permissionSet.deleteAttachment = true
      permissionSet.editSoilForm = true
    }

    if (canEdit) {
      permissionSet.editConcreteForm = true
      permissionSet.approveConcrete = true
    }

    return permissionSet
  }

  public get soilsPermissionSet(): SoilsPermissionSet {
    const canEdit =
      this.userPermissionLevel === this.getPermissionLevel('Power User') ||
      this.userPermissionLevel === this.getPermissionLevel('Admin')
    let permissionSet = new SoilsPermissionSet()

    if (canEdit) {
      permissionSet.moveTests = true
      permissionSet.createTest = true
      permissionSet.retest = true
      permissionSet.editForm = true
    }
    return permissionSet
  }

  public get concretePermissionSet(): ConcretePermissionSet {
    const canEdit =
      this.userPermissionLevel === this.getPermissionLevel('Power User') ||
      this.userPermissionLevel === this.getPermissionLevel('Admin')
    let permissionSet = new ConcretePermissionSet()

    if (canEdit) {
      permissionSet.editForm = true
    }

    if (canEdit) {
      permissionSet.viewReport = true
      permissionSet.createReportForDistribution = true
      permissionSet.sendReport = true
    }
    return permissionSet
  }

  public get distributionPermissionSet(): DistributionPermissionSet {
    const canEdit =
      this.userPermissionLevel === this.getPermissionLevel('Power User') ||
      this.userPermissionLevel === this.getPermissionLevel('Admin')
    let permissionSet = new DistributionPermissionSet()

    if (canEdit) {
      permissionSet.saveForDistribution = true
      permissionSet.create = true
      permissionSet.send = true
      permissionSet.email = true
      permissionSet.delete = true
    }

    permissionSet.download = true
    return permissionSet
  }
}
