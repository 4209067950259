import { Injectable } from '@angular/core';

@Injectable()
export class ReportConstants {

  public TypeName = {
    soil: 'Soils',
    concrete: 'Concrete',
    field: 'Field',
    log: 'Log'
  };

  public TypeValue = {
    soil: 'soil',
    concrete: 'concrete',
    field: 'field',
    log: 'log',
  }

  public ReportName = { 
    soilCheckPlug: 'Check Plug',
    soilException: 'Exception',
    soilInPlaceDensity: 'In-Place Density',
    concreteACI214: 'ACI 214',
    concreteBreakList: 'Break List',
    concreteTestReport: 'Test Report',
    concreteSummaryStrengthTest: 'Summary Strength Test',
    concreteInvoiceDetail: 'Invoice Detail',
    concreteInvoiceSummary: 'Invoice Summary',
    fieldReport: 'Field Report',
    logDiscrepancy: 'Discrepancy',
    logRock: 'Rock',
    logUndercut: 'Undercut'
  }

  public ReportValue = {
    soilCheckPlug: 'soil_chp',
    soilException: 'soil_exc',
    soilInPlaceDensity: 'soil_ipd',
    concreteACI214: 'concrete_aci',
    concreteBreakList: 'concrete_brk',
    concreteTestReport: 'concrete_tst',
    concreteSummaryStrengthTest: 'concrete_sst',
    concreteInvoiceDetail: 'concrete_ivd',
    concreteInvoiceSummary: 'concrete_ivs',
    fieldReport: 'field_rpt',
    logDiscrepancy: 'log_dsc',
    logRock: 'log_rck',
    logUndercut: 'log_und'
  }

  public ReportFile = {
    soilCheckPlug: 'SoilCheckPlug.trdp',
    soilException: 'SoilException.trdp',
    soilInPlaceDensity: 'SoilInPlaceDensity.trdp',
    concreteACI214: 'ConcreteACI214.trdp',
    concreteBreakList: 'ConcreteBreakList.trdp',
    concreteTestReport: 'ConcreteTest.trdp',
    concreteSummaryStrengthTest: 'ConcreteSummaryStrengthTest.trdp',
    concreteInvoiceDetail: 'ConcreteInvoiceDetail.trdp',
    concreteInvoiceSummary: 'ConcreteInvoiceSummary.trdp',
    fieldReport: 'FieldReport.trdp',
    logDiscrepancy: 'LogDiscrepancy.trdp',
    logRock: 'LogRock.trdp',
    logUndercut: 'LogUndercut.trdp'
  }

  GetReportFileByValue( reportValue: string ) : string {
    switch( reportValue ) {
        case this.ReportValue.soilCheckPlug: return this.ReportFile.soilCheckPlug;
        case this.ReportValue.soilException: return this.ReportFile.soilException;
        case this.ReportValue.soilInPlaceDensity: return this.ReportFile.soilInPlaceDensity;
        case this.ReportValue.concreteACI214: return this.ReportFile.concreteACI214;
        case this.ReportValue.concreteBreakList: return this.ReportFile.concreteBreakList;
        case this.ReportValue.concreteTestReport: return this.ReportFile.concreteTestReport;
        case this.ReportValue.concreteSummaryStrengthTest: return this.ReportFile.concreteSummaryStrengthTest;
        case this.ReportValue.concreteInvoiceDetail: return this.ReportFile.concreteInvoiceDetail;
        case this.ReportValue.concreteInvoiceSummary: return this.ReportFile.concreteInvoiceSummary;
        case this.ReportValue.fieldReport: return this.ReportFile.fieldReport;
        case this.ReportValue.logDiscrepancy: return this.ReportFile.logDiscrepancy;
        case this.ReportValue.logRock: return this.ReportFile.logRock;
        case this.ReportValue.logUndercut: return this.ReportFile.logUndercut;
    }
    return '';
  }

}