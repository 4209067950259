<div class="navbar-top" [ngClass]="fuseConfig.layout.navbar.secondaryBackground">

  <div class="logo" [@fadeLogo]="isLogoFaded">
    <img class="logo-icon" src="assets/images/logos/GeoTech.png">

    <div fxLayout="column" fxLayoutAlign="space-between start" class="ml-2 mt-16">
      <span class="logo-text secondary-text">CASTS</span>
      <span class="environment-text" [ngClass]="environmentClass">{{ environmentLabel }} <span class="app-version">v. {{appVersion}}</span></span>
    </div>
  </div>

  <span *ngIf="!isSidebarOpen" class="environment-letter"
    [ngClass]="environmentClass">{{ environmentLabel?.charAt(0) }} </span>

</div>


<div class="navbar-scroll-container" [ngClass]="fuseConfig.layout.navbar.primaryBackground" fusePerfectScrollbar
  [fusePerfectScrollbarOptions]="{suppressScrollX: true}">

  <div class="navbar-content">
    <fuse-navigation class="material2" layout="vertical"></fuse-navigation>
  </div>

</div>
