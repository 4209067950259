<mat-card class="example-card centered">
  <div>
    <h2 class="inline">Login</h2>
    <img class="login-logo" src="assets/images/logos/GeoTech.png">
  </div>

  <form [formGroup]="loginForm" class="example-form" (submit)="login()">

    <mat-card-content>
      <table class="example-full-width" cellspacing="0">
        <tr>
          <td>
            <mat-form-field class="example-full-width">
              <input matInput placeholder="Username" formControlName="username" name="username" required>
            </mat-form-field>
          </td>
        </tr>
        <tr>
          <td>
            <mat-form-field class="example-full-width">
              <input matInput placeholder="Password" formControlName="password" type="password" name="password"
                required>
            </mat-form-field>
          </td>
        </tr>
      </table>
    </mat-card-content>

    <mat-card-actions>
      <button mat-raised-button color="accent" id="login-button" class="button" type="submit"
        [disabled]="loading">Login</button>
    </mat-card-actions>

  </form>

</mat-card>