import { DecimalPipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, RouterModule } from '@angular/router';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { AppComponent } from 'app/app.component';
import { fuseConfig } from 'app/fuse-config';
import { LayoutModule } from 'app/layout/layout.module';
import { MessagesConstants } from 'app/main/shared/constants/messages.constants';
import { ErrorInterceptor } from './main/authentification/error-interceptor';
import { HasChangesGuard } from './main/authentification/has-changes-guard';
import { JwtInterceptor } from './main/authentification/jwt-interceptor';
import { ProgressBarInterceptor } from './main/authentification/progress-bar-interceptor';
import { LoginModule } from './main/login/login.module';
import { ConfirmationDialogComponent } from './main/shared/components/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogModule } from './main/shared/components/confirmation-dialog/confirmation-dialog.module';
import { InformationDialogComponent } from './main/shared/components/information-dialog/information-dialog.component';
import { InformationDialogModule } from './main/shared/components/information-dialog/information-dialog.module';
import { ReportAutomationDialogComponent } from './main/shared/components/report-automation-dialog/report-automation-dialog.component';
import { ReportAutomationDialogModule } from './main/shared/components/report-automation-dialog/report-automation-dialog.module';
import { ApiConstants } from './main/shared/constants/api.constants';
import { ReportConstants } from './main/shared/constants/report.constants';
import { RouteConstants } from './main/shared/constants/routes.constants';
import { FormHelper } from './main/shared/helpers/form-helper';
import { HttpHelper } from './main/shared/helpers/http-helper';
import { RoundingHelper } from './main/shared/helpers/roundind-helper';
import { TimeHelper } from './main/shared/helpers/time-helper';
import { MaterialModule } from './material/material.module';
import { BillingService } from './services/billing.service';
import { CustomPreloadingStrategy } from './services/custrom-preloading.strategy';
import { MenuService } from './services/menu.service';
import { PermissionService } from './services/permission.service';
import { ProjectService } from './services/project.service';
import { ToastService } from './services/toast.service';
import { ValidatorsService } from './services/validators.service';

@NgModule({
  declarations: [
    AppComponent
  ],

  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(RouteConstants, {
      preloadingStrategy: CustomPreloadingStrategy
    } as ExtraOptions),

    TranslateModule.forRoot(),

    // Material
    MaterialModule,

    // Fuse modules
    FuseModule.forRoot(fuseConfig),
    FuseProgressBarModule,
    FuseSharedModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,

    // App modules
    LayoutModule,
    LoginModule,

    ConfirmationDialogModule,
    InformationDialogModule,
    ReportAutomationDialogModule,
  ],

  providers: [
    ApiConstants,
    BillingService,
    ToastService,
    MenuService,
    ValidatorsService,
    ProjectService,
    MessagesConstants,
    ReportConstants,
    FormHelper,
    HttpHelper,
    TimeHelper,
    RoundingHelper,
    HasChangesGuard,
    DecimalPipe,
    PermissionService,
    CustomPreloadingStrategy,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ProgressBarInterceptor, multi: true }
  ],

  bootstrap: [
    AppComponent
  ],

  entryComponents: [
    ConfirmationDialogComponent,
    InformationDialogComponent,
    ReportAutomationDialogComponent
  ]
})
export class AppModule {
}
