import { NgModule } from "@angular/core";
import { DefaultValuePipe } from "./default-value.pipe";
import { DistributionResultPipe } from "./distribution-result.pipe";
import { KeysPipe } from "./object-keys.pipe";
import { PassedFailedPipe } from "./passed-failed-pipe";
import { ReplaceUnknownValuePipe } from "./replace-unknown-value.pipe";
import { RoundingPipe } from "./rounding.pipe";
import { SafePipe } from "./safe.pipe";
import { TimeToLocalePipe } from "./time-to-locale.pipe";

@NgModule({
  declarations: [
    KeysPipe,
    PassedFailedPipe,
    SafePipe,
    TimeToLocalePipe,
    RoundingPipe,
    ReplaceUnknownValuePipe,
    DistributionResultPipe,
    DefaultValuePipe,
  ],
  exports: [
    KeysPipe,
    PassedFailedPipe,
    SafePipe,
    TimeToLocalePipe,
    RoundingPipe,
    ReplaceUnknownValuePipe,
    DistributionResultPipe,
    DefaultValuePipe,
  ],
})
export class PipesModule {}
