import { Route } from "@angular/compiler/src/core";
import { Injectable } from "@angular/core";
import { PreloadingStrategy } from "@angular/router";
import { FuseProgressBarService } from "@fuse/components/progress-bar/progress-bar.service";
import { Observable, of, timer, zip } from "rxjs";
import { filter, flatMap, map, skip } from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class CustomPreloadingStrategy implements PreloadingStrategy {
  public static readonly PRELOADING_DELAY = 2000;

  constructor(
    private progressBarService: FuseProgressBarService
  ) {

  }

  preload(route: Route, load: () => Observable<any>): Observable<any> {
    if (this.hasGoodConnection) {
      return zip(
        this.onDataLoadingFinish,
        timer(CustomPreloadingStrategy.PRELOADING_DELAY)
      ).pipe(
        flatMap(() => {
          return load();
        })
      );
    } else {
      return of(null);
    }
  }

  private get onDataLoadingFinish(): Observable<any> {
    return this.progressBarService.loadingCountObservable.pipe(skip(1), filter(x => x < 1), map(() => true));
  }

  private get hasGoodConnection(): boolean {
    const conn = (navigator as any).connection;
    if (conn) {
      if (conn.saveData) {
        return false; // save data mode is enabled, so dont preload
      }
      const avoidTheseConnections = ['slow-2g', '2g'];
      const effectiveType = conn.effectiveType || '';
      if (avoidTheseConnections.includes(effectiveType)) {
        return false;
      }
    }
    return true;
  }
}