import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConstants } from 'app/main/shared/constants/api.constants';
import { Distribution } from 'app/main/shared/models/distribution.model';
import { MixDesign } from 'app/main/shared/models/mix-design.model';
import { ProjectQuery } from 'app/main/shared/models/project-query.model';
import { Project } from 'app/main/shared/models/project.model';
import { PaginatedResult } from 'app/main/shared/paging/paginated.result.model';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class ProjectService {
  constructor(
    private http: HttpClient,
    private api: ApiConstants,
  ) { }

  get(id: number): Observable<Project> {
    return this.http.get<Project>(this.api.Project.get, {
      params: {
        id: id.toString()
      }
    });
  }

  findQuick(value: string): Observable<Project[]> {
    return this.http.get<[]>(this.api.Project.findQuick, {
      params: {
        value: value
      }
    });
  }

  findQuickWithExistingLog(value: string, logName: string): Observable<Project[]> {
    if (!['RockLog', 'UndercutLog', 'DiscrepancyLog'].some(l => l == logName)) throw new Error("Invalid log name.");
    return this.http.get<Project[]>(this.api.Project.findQuickWithExistingLog + "/" + logName, {
      params: {
        value: value
      }
    });
  }

  findQuickWithExistingFieldReport(value: string): Observable<Project[]> {
    return this.http.get<Project[]>(this.api.Project.findQuickWithExistingFieldReport, {
      params: {
        value: value
      }
    });
  }

  findQuickWithExistingSoilTest(value: string): Observable<Project[]> {
    return this.http.get<Project[]>(this.api.Project.findQuickWithExistingSoilTest, {
      params: {
        value: value
      }
    });
  }

  findQuickWithExistingConcreteTest(value: string): Observable<Project[]> {
    return this.http.get<Project[]>(this.api.Project.findQuickWithExistingConcreteTest, {
      params: {
        value: value
      }
    });
  }

  findQuickWithExistingUnapprovedFieldReportConcreteTest(value: string): Observable<Project[]> {
    return this.http.get<Project[]>(this.api.Project.findQuickWithExistingUnapprovedFieldReportConcreteTest, {
      params: {
        value: value
      }
    });
  }

  findQuickActive(value: string): Observable<Project[]> {
    return this.http.get<Project[]>(this.api.Project.findQuickActive, {
      params: {
        value: value
      }
    });
  }

  getAll(page: number, pageSize: number): Observable<PaginatedResult<Project>> {
    return this.http.get<PaginatedResult<Project>>(this.api.Project.getAll, {
      params: {
        page: page.toString(),
        pageSize: pageSize.toString()
      }
    });
  }

  query(filter: ProjectQuery): Observable<PaginatedResult<Project>> {
    return this.http.post<PaginatedResult<Project>>(this.api.Project.query, filter);
  }

  updateDetails(entity: any): Observable<any> {
    return this.http.put(this.api.Project.updateDetails, entity);
  }

  syncAllProjects(): Observable<Object> {
    return this.http.get(this.api.Project.syncProjects);
  }

  syncSpecificProject(projectNumber: string): Observable<Object> {
    return this.http.get(this.api.Project.syncProjects, {
      params: {
        specificProjectNumber: projectNumber
      }
    });
  }

  isSyncInProgress(projectNumber: string = ''): Observable<boolean> {
    return this.http.get<boolean>(this.api.Project.isSyncInProgress, {
      params: {
        specificProjectNumber: projectNumber
      }
    });
  }

  getDistributions(projectId: number): Observable<Distribution[]> {
    return this.http.get<Distribution[]>(this.api.Project.getDistributions.replace('{projectId}', projectId.toString()));
  }

  updateDistribution(projectId: number, entities: Distribution[]): Observable<any> {
    return this.http.put(this.api.Project.updateDistribution.replace('{projectId}', projectId.toString()), entities);
  }

  getMixDesigns(projectId: number): Observable<MixDesign[]> {
    return this.http.get<MixDesign[]>(this.api.Project.getMixDesigns.replace('{projectId}', projectId.toString()));
  }

  updateMixDesign(projectId: number, entities: MixDesign[]): Observable<any> {
    return this.http.put(this.api.Project.updateMixDesign.replace('{projectId}', projectId.toString()), entities);
  }

  projectNumberExists(projectNumber: string): Observable<boolean> {
    return this.http.get<boolean>(this.api.Project.projectNumberExists, {
      params: {
        projectNumber: projectNumber
      }
    });
  }

  soilTestCount(id: number): Observable<number> {
    return this.http.get<number>(this.api.Project.soilTestCount, { params: { id: id.toString() } });
  }

  concreteTestCount(id: number): Observable<number> {
    return this.http.get<number>(this.api.Project.concreteTestCount, { params: { id: id.toString() } });
  }

}
