import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class MenuService {

  constructor(private authenticationService: AuthenticationService) {
  }

  public get navigation() {
    return [
      {
        id: 'billing',
        title: 'Billing',
        type: 'item',
        icon: 'receipt',
        url: '/billing'
      },
      {
        id: 'projects',
        title: 'Projects',
        type: 'item',
        icon: 'view_module',
        url: '/projects'
      },
      {
        id: 'contacts',
        title: 'Contacts',
        type: 'item',
        icon: 'people',
        url: '/contacts'
      },
      {
        id: 'field-reports',
        title: 'Field',
        type: 'item',
        icon: 'tablet_mac',
        url: '/field-reports'
      },
      {
        id: 'soil-tests',
        title: 'Soils',
        type: 'item',
        icon: 'landscape',
        url: '/soil-tests'
      },
      {
        id: 'concrete',
        title: 'Concrete',
        type: 'collapsable',
        icon: 'business',
        children: [
          {
            id: 'concrete-search',
            title: 'Search By Project',
            type: 'item',
            icon: 'search',
            url: '/concrete/search-by-project'
          },
          {
            id: 'concrete-tests',
            title: 'Concrete Tests',
            type: 'item',
            icon: 'folder',
            url: '/concrete/tests'
          },
          {
            id: 'concrete-approve-pours',
            title: 'Approve Pours',
            type: 'item',
            icon: 'done_all',
            url: '/concrete/approve-pours'
          },
          {
            id: 'concrete-cylinder-check-in',
            title: 'Cylinder Check-In',
            type: 'item',
            icon: 'check_circle',
            url: '/concrete/cylinder-check-in'
          },
          {
            id: 'concrete-break-list',
            title: 'Break List',
            type: 'item',
            icon: 'gavel',
            url: '/concrete/break-list',
          }
        ]
      },
      {
        id: 'reports',
        title: 'Reporting',
        type: 'collapsable',
        icon: 'insert_drive_file',
        children: [
          {
            id: 'report-builder',
            title: 'Build',
            type: 'item',
            icon: 'picture_as_pdf',
            url: '/reports/builder'
          },
          {
            id: 'report-distribution',
            title: 'Distribute',
            type: 'item',
            icon: 'email',
            url: '/reports/distribution',
            // badge: {
            //   title: '118',
            //   bg: '#EF6D31',
            //   fg: '#FFFFFF'
            // }
          }
        ]
      },
      {
        id: 'logs',
        title: 'Logs',
        type: 'collapsable',
        icon: 'work',
        children: [
          {
            id: 'logs-rock',
            title: 'Rock',
            type: 'item',
            icon: 'table_chart',
            url: '/logs/rock'
          },
          {
            id: 'logs-undercut',
            title: 'Undercut',
            type: 'item',
            icon: 'table_chart',
            url: '/logs/undercut'
          },
          {
            id: 'logs-discrepancy',
            title: 'Discrepancy',
            type: 'item',
            icon: 'table_chart',
            url: '/logs/discrepancy'
          }
        ]
      },
      {
        id: 'admin',
        title: 'Settings',
        type: 'collapsable',
        icon: 'settings_application',
        hidden: !(this.authenticationService.isAdmin()),
        children: [
          {
            id: 'admin-users',
            title: 'User',
            type: 'item',
            icon: 'supervised_user_circle',
            url: '/users',
          },
          {
            id: 'admin-lookups',
            title: 'Lookup',
            type: 'item',
            icon: 'view_headline',
            url: '/lookups',
          },
          {
            id: 'admin-settings',
            title: 'Application',
            type: 'item',
            icon: 'settings_applications',
            url: '/settings',
          }
        ]
      }
    ];
  }
}
