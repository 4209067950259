import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { ApiConstants } from 'app/main/shared/constants/api.constants';
import { Environment } from 'app/main/shared/models/environment.model';
import { Setting } from 'app/main/shared/models/setting.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable()
export class SettingsService {
  private environmentSubject: BehaviorSubject<Environment> = new BehaviorSubject<Environment>(null);

  public get environment(): Environment {
    return this.environmentSubject.value
  };

  public environemntObservable(): Observable<Environment> {
    return this.environmentSubject.asObservable().pipe(filter(value => !!value));
  }

  constructor(private http: HttpClient, private api: ApiConstants) {
    this.getEnvironment().subscribe((env) => {
      this.environmentSubject.next(env);
    })
  }

  public getAllSettings(): Observable<Setting[]> {
    return this.http.get<Setting[]>(this.api.Settings.getAll);
  }

  public updateSettings(settings: Setting[]): Observable<Setting[]> {
    return this.http.post<Setting[]>(this.api.Settings.updateSettings, settings);
  }

  public getFieldReportAttachmentPath(): Observable<Setting> {
    return this.http.get<Setting>(this.api.Settings.getFieldReportAttachmentPath);
  }

  public getDistributionAttachmentPath(): Observable<Setting> {
    return this.http.get<Setting>(this.api.Settings.getDistributionAttachmentPath);
  }

  private getEnvironment(): Observable<Environment> {
    return this.http.get<Environment>(this.api.Settings.getEnvironment);
  }
}