import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class ToastService {
    constructor(public snackBar: MatSnackBar) { }

    public showToast(message: string, duration = 5000) {
        this.snackBar.open(message, "Close", {
            duration,
        });
    }

    public dismissToast() {
        this.snackBar.dismiss();
    }
}
