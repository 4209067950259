<!-- PROGRESS BAR -->
<fuse-progress-bar></fuse-progress-bar>
<!-- / PROGRESS BAR -->

<!-- VERTICAL LAYOUT 1 -->
<ng-container *ngIf="isAuthenticated()">
    <vertical-layout-1></vertical-layout-1>
</ng-container>

<!-- / LOGIN PAGE -->
<ng-container *ngIf="!isAuthenticated()">
    <login></login>
</ng-container>