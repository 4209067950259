import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FuseProgressBarService {
  // Private
  private _bufferValue: BehaviorSubject<number>;
  private _mode: BehaviorSubject<string>;
  private _value: BehaviorSubject<number>;
  private _visibleCount: BehaviorSubject<number>;

  /**
   * Constructor
   *
   * @param {Router} _router
   */
  constructor(
    private _router: Router
  ) {
    // Initialize the service
    this._init();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Buffer value
   */
  get bufferValue(): Observable<any> {
    return this._bufferValue.asObservable();
  }

  setBufferValue(value: number): void {
    this._bufferValue.next(value);
  }

  /**
   * Mode
   */
  get mode(): Observable<any> {
    return this._mode.asObservable();
  }

  setMode(value: 'determinate' | 'indeterminate' | 'buffer' | 'query'): void {
    this._mode.next(value);
  }

  /**
   * Value
   */
  get value(): Observable<any> {
    return this._value.asObservable();
  }

  setValue(value: number): void {
    this._value.next(value);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Initialize
   *
   * @private
   */
  private _init(): void {
    // Initialize the behavior subjects
    this._bufferValue = new BehaviorSubject(0);
    this._mode = new BehaviorSubject('indeterminate');
    this._value = new BehaviorSubject(0);
    this._visibleCount = new BehaviorSubject(0);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Count of loading process
   */
  public get loadingCount(): number {
    return this._visibleCount.value;
  }

  /**
  * Visible
  */
  public get visible(): Observable<boolean> {
    return this._visibleCount.pipe(
      map(count => count > 0)
    );
  }

  public get isVisible(): boolean {
    return this._visibleCount.value > 0;
  }

  /**
   * Increase loading counter
   */
  show(): void {
    this._visibleCount.next(this.loadingCount + 1);
  }

  /**
   * Decrease loading counter
   */
  hide(): void {
    if (this.loadingCount == 0) return;
    this._visibleCount.next(this.loadingCount - 1);
  }

  /**
   * Reset loading counter
   */
  reset(): void {
    this._visibleCount.next(0);
  }

  get loadingCountObservable(): Observable<number> {
    return this._visibleCount.asObservable();
  }
}

