import { Injectable } from "@angular/core";
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class TimeHelper {
  public timeToLocale(time: string, localePattern: string = "hh:mm A", timePattern: string = "HH:mm:ss"): string {
    if (time === "" || time == null) return "";
    return moment(time, timePattern).format(localePattern);
  }

  public localeToTime(formValue: string, localePattern: string = "hh:mm A", timePattern: string = "HH:mm:ss"): string | null {
    if (formValue === "" || formValue == null) return "";
    return moment(formValue, localePattern).format(timePattern);
  }

  public removeTimeZone(date: string | Date): Date {
    //determine if input date is string or Date
    if (typeof date['getMonth'] !== 'function') {
      date = new Date(date);
    }

    date = date as Date;
    date.setUTCHours(24);
    return date;
  }
}