import { Injectable } from "@angular/core";
import { AbstractControl, AsyncValidatorFn, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ProjectService } from "./project.service";

@Injectable({
  providedIn: "root"
})
export class ValidatorsService {
  constructor(private projectService: ProjectService) {
  }

  public get integerValidator(): ValidatorFn {
    return Validators.pattern("^[0-9]*$");
  }

  public get decimalValidator(): ValidatorFn {
    return Validators.pattern("(\\d+)(\\.)?(\\d+)?");
  }

  public timeValidator(): ValidatorFn {
    return Validators.pattern("((1[0-2]|0?[0-9]):([0-5][0-9]) ([AaPp][Mm]))");
  }

  public get multiEmailValidator(): ValidatorFn {
    return Validators.pattern("([\\d\\w.]{3,}@[\\d\\w.]{3,},? *)+");
  }

  public get projectNumberValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
      return this.projectService.projectNumberExists(control.value)
        .pipe(
          map(value => !value ? { "projectNumberNotExists": true } : null)
        );
    };
  }

  public arrayContainsValidator(values: string[], caseSensitive: boolean = true): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!values.find(value => {
        if (caseSensitive) {
          return value == control.value;
        }
        return value.toLowerCase() == control.value.toLowerCase();
      })
      ) return { "valueNotFound": true };
      return null;
    }
  }

  public duplicityValidator(values: string[], caseSensitive: boolean = true): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (values.some(value => {
        if (caseSensitive) {
          return value == control.value;
        }
        return value.toLowerCase() == control.value.toLowerCase();
      })
      ) return { "duplicityValue": true };
      return null;
    }
  }
}

