import { EventEmitter, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { ConfirmationDialogComponent } from '../shared/components/confirmation-dialog/confirmation-dialog.component';
import { MessagesConstants } from '../shared/constants/messages.constants';
import { HasChanges } from '../shared/interfaces/has-changes';

@Injectable({
  providedIn: 'root'
})
export class HasChangesGuard implements CanDeactivate<HasChanges> {
  public canDeactivateEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private dialog: MatDialog,
    private messages: MessagesConstants
  ) { }

  canDeactivate(target: HasChanges): boolean | Observable<boolean> {
    if (target && target.hasChanges) {
      let confirmation = this.dialog.open<ConfirmationDialogComponent>(ConfirmationDialogComponent, { width: '20%', minWidth: 400 });
      confirmation.componentInstance.text = this.messages.DialogMessages.leave;
      confirmation.afterClosed().pipe(take(1)).subscribe(result => this.canDeactivateEvent.next(result));
      return confirmation.afterClosed();
    } else {
      this.canDeactivateEvent.next(true);
      return true;
    }
  }
}
