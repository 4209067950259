import { ReportPermissionSet } from "./report-permission-set.model";

export class FieldPermissionSet extends ReportPermissionSet {
  editForm: boolean = false;
  delete: boolean = false;
  removeReportCreated: boolean = false;
  markReportCreated: boolean = false;
  approve: boolean = false;
  sendPrelim: boolean = false;
  deleteAttachment: boolean = false;

  editSoilForm: boolean = false;
  editConcreteForm: boolean = false;
  approveConcrete: boolean = false;

}